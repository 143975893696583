export const locale = {
    lang: 'en',
    data: {
        'NAV': {
                'APPLICATIONS': 'APPLICATIONS',
                'SAMPLE'        : {
                    'TITLE': 'Sample',
                    'BADGE': '25'
            },
            'TESTE' : {
                    'TITLE': 'CLOVIS'
                    
            },
            'SCRUMBOARD'  : {
                'TITLE': 'Scrumboard'
            },
            'PROJECTS'    : {
                'TITLE': 'Projects',
                'TIMELINE': 'Timeline'
            },
            'ADMINISTRATION': 'ADMINISTRATION', 
                'CLIENT'    : {
                    'TITLE': 'Customers'
                },  
                'PROJECTTYPE'    : {
                    'TITLE': 'Project Type'
                },
            'CONFIGURACAO': 'Configuration', 
            'USER'    : {
                'TITLE': 'Users',
                "SIGNUPS": "SignUps",
                "PARTNERS": "Partners",
                "LOGIN": "Login Config.",
                "BANNER": "Banner Config.",
            }, 
            "APRENDIZADO": {
                "TITLE": "My learning",
                "CURSOS": "Courses",
                "ROADMAP": "Knowledge Trail"
            },
        },
        "HELPDESK": "Feedback Central",
        "MEMORY": "Experiênce",
        "GAMIFICATION": {
            "MEMORY": {
                "INFO": "Information",
                "RANKING": "Ranking",
            }
        },
        "CONTENT": {
            "HOME": "Content Management",
            "COURSE": {
                "CONTENTS": "Course Contents",
                "BADGES": "Badges"
            }
        },
        "POSITIONS": {
            "POSITIONS": "Jobs",
            "OPEN": "Open Positions",
            "FORM": "New Position"
        },
        "JOBS": {
            "JOBS": "Jobs",
            "FIND": "Find Jobs",
            "MY": "Matches"
        },
        "FINANCE": {
            "FINANCE": "Finance",
            "BUY": "Buy Matches"
        }
    }
};
