export const locale = {
    lang: 'br',
    data: {
        'NAV': {
            'APPLICATIONS': 'APLICAÇÕES',
            'SAMPLE'        : {
                'TITLE': 'Amostra',
                'BADGE': '25'
            },
            'TESTE' : {
                'TITLE': 'CLOVIS'
                
            },
            'SCRUMBOARD'  : {
            'TITLE': 'Scrumboard'
                },
                'PROJECTS'    : {
                'TITLE': 'Projetos',
                'TIMELINE': 'Timeline'
            },
            'ADMINISTRATION': 'ADMINISTRAÇÃO', 
            'CLIENT'    : {
                'TITLE': 'Clientes'
            },  
            'PROJECTTYPE'    : {
                'TITLE': 'Tipos de Projeto'
            }, 
            'CONFIGURACAO': 'Configuração', 
            'USER'    : {
                'TITLE': 'Usuários',
                "SIGNUPS": "Inscritos",
                "PARTNERS": "Parceiros",
                "LOGIN": "Config. Login",
                "BANNER": "Config. Banner",
            }, 
            "APRENDIZADO": {
                "TITLE": "Meu aprendizado",
                "CURSOS": "Cursos",
                "ROADMAP": "Trilha de conhecimento"
            },            
            "FINANCE": {
                "TITLE": "Financeiro",
                "PAYMENTS": "Minhas mensalidades",
            },            
            "SECRETARY": {
                "TITLE": "Secretaria",
                "OPEN": "Abertura de Chamado",
                "REQUESTS": "Meus chamados",
                "ANSWER": "Responder chamados",
            },           
            "LIBRARY": {
                "TITLE": "Biblioteca",
            },
        },
        "HELPDESK": "Central de Feedback",
        "MEMORY": "Experiência",
        "GAMIFICATION": {
            "MEMORY": {
                "INFO": "Informações",
                "RANKING": "Ranking",
            }
        },
        "CONTENT": {
            "HOME": "Cadastro de Conteúdo",
            "COURSE": {
                "CONTENTS": "Conteúdo",
                "BADGES": "Badges"
            }
        },
        "POSITIONS": {
            "POSITIONS": "Vagas",
            "OPEN": "Vagas Abertas",
            "FORM": "Cadastrar Vaga"
        },
        "JOBS": {
            "JOBS": "Vagas",
            "FIND": "Vagas Abertas",
            "MY": "Minhas Vagas"
        },
    }
};
