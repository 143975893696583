// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    hmr       : false,
    firebaseConfig: {
        apiKey: "AIzaSyAfjg9CYtYa-Ajybxxvmam3ZyCqQsRHYVA",
        authDomain: "ensino-superior-faculdadeiv2.firebaseapp.com",
        projectId: "ensino-superior-faculdadeiv2",
        storageBucket: "ensino-superior-faculdadeiv2.appspot.com",
        messagingSenderId: "339424532079",
        appId: "1:339424532079:web:02fd83aebb74d7876fdf9a",
        measurementId: "G-N87E7BYXL0"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
