import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';

import { Observable, of, BehaviorSubject} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserModel } from './user.model';
import { datastore } from 'app/config';
import { TranslateService } from '@ngx-translate/core';
import _ = require('lodash');
import { PoNotification, PoNotificationService } from '@portinari/portinari-ui';
import { MailerService } from '../apps/shared/mailer.service';
import { Perfis } from '../apps/adm/shared/perfis.enum';

import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthErrors$ = this.eventAuthError.asObservable();
  user$: Observable<any>;
  newUser: any;


  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private _translateService: TranslateService,
    private poNotification: PoNotificationService,
    public serviceMailer: MailerService,
  ){
    
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if(user){
          return this.afs.doc<UserModel>(`${datastore}/users/${user.uid}`).valueChanges();
        }else{
          return of(null);
        }
      })
    )
  }

  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    //if(credential.user.email.split("@")[1] == "iv2.com.br"){
    return this.updateUserData(credential.user);
    //}else{
      //return false;
    //}
    
  }

  async onLoginFacebook() {
    const providerFace = new auth.FacebookAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(providerFace);
    return this.updateUserData(credential.user);
    
  }


  async login(email: string, password: string){
    this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .catch(error => {
        this.eventAuthError.next(error);
      }).then(userCredential =>{
        if(userCredential){
          this.getDataUser(userCredential.user.uid).subscribe(action => {
            if (!action) {
              const notifiy: PoNotification = {
                message: "Houve um problema na criação da sua conta, contate um administrador.",
                duration: 10000
              }
              this.poNotification.error(notifiy);
            } else if (!action.ativo){
              const notifiy: PoNotification = {
                message: "Acesso não liberado pelo administrador, aguarde a liberação!",
                duration: 10000
              }
              this.poNotification.error(notifiy);
              //this.a({ message: "Seu cadastro foi efetuado com sucesso, aguarde o administrador liberar seu acesso!"})
              this.router.navigate(['/auth/login']);
            } else {
              this.router.navigate(['/apps/home']);
            }
          })
          
        }
      })
  }

  async signOut() {    
    await this.afAuth.auth.signOut();
    this.router.navigate(['/auth/login']);
    location.reload();
  }

  async createUser(user){
    const credential = this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password)
      .then(userCredential => {
        
        const data = {
          id: userCredential.user.uid,
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          displayName: user.name,
          photoURL: 'assets/images/avatars/profile.jpg',
          profile: Perfis.Resource,
          ativo: true
        }

        return this.updateUserData(data);  

      }).catch( error => {
        const notifiy: PoNotification = {
          message: "Houve um erro ao criar sua conta. Contate um administrador.",
          duration: 10000
        }
  
        this.poNotification.success(notifiy);
      })
      
  }

  async createCompany(user) {
    const credential = this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password)
      .then(userCredential => {
        
        const data = {
          id: userCredential.user.uid,
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          displayName: user.name,
          photoURL: 'assets/images/avatars/profile.jpg',
          profile: Perfis.Empresa,
          ativo: false,
          ...user
        }

        delete data.password;
        delete data.passwordConfirm;

        this.afs.collection(`${datastore}/users`)
        .doc(userCredential.user.uid)
        .set(data).then(() => {          
          return this.router.navigate(['/auth/login']);
        });

      }).catch( error => {
        const notifiy: PoNotification = {
          message: "Houve um erro ao criar sua conta. Contate um administrador.",
          duration: 10000
        }
  
        this.poNotification.success(notifiy);
      })
      
  }

  async resetPassword(email: string){
    this.afAuth.auth.sendPasswordResetEmail(email)
      .catch( error => {
        this.eventAuthError.next(error);
      });

      const notifiy: PoNotification = {
        message: "Link para resetar sua senha enviada no seu e-mail!",
        duration: 10000
      }

      this.poNotification.success(notifiy);

      
  }

  private async updateUserData({ uid, email, displayName, photoURL, ativo} : UserModel){
    let aviso = 0;
    const userRef: AngularFirestoreDocument<UserModel> = this.afs.doc(`${datastore}/users/${uid}`);
    this.getDataUser(uid).subscribe(action => {
      
      if(_.isUndefined(action)){
        
        const data = {
          id: uid,
          uid,
          email,
          displayName,
          photoURL,
          profile: Perfis.Resource,
          ativo: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
    
        userRef.set(data, {merge: true});
        
        const notifiy: PoNotification = {
          message: "Obrigado por se inscrever na nossa plataforma, por favor finalize o seu cadastro preenchendo o formulario.",
          duration: 10000
        }

        this.poNotification.success(notifiy);
        aviso = 1
        
        this.serviceMailer.senMailCreateLogin(email, displayName, photoURL);
        // this.serviceMailer.senMailAdminLiberacao(email, displayName, photoURL);

        return this.router.navigate(['/auth/login']);

      }else{
        if(!_.isUndefined(action.ativo) && action.ativo == false){
          
          const data = {
            id: uid,
            uid,
            email,
            displayName,
            photoURL,
          }
      
          userRef.set(data, {merge: true});
          
          if(aviso == 0){
            const notifiy: PoNotification = {
              message: "Acesso não liberado pelo administrador, aguarde a liberação!",
              duration: 10000
            }
            this.poNotification.error(notifiy);
          }
         
          return this.router.navigate(['/auth/login']);

        }else{
          
          const data = {
            id: uid,
            uid,
            email,
            displayName,
            photoURL,
          }
          
          userRef.set(data, {merge: true});
          return this.router.navigate(['/apps/home'])
        }

      }
      
    });
    
   

    //await userRef.set(data, {merge: true});
    //return this.router.navigate(['/apps/project'])
  }

  getDataUser = function(uid) {
      return this.afs.collection(`${datastore}/users`).doc(uid).valueChanges();
  }
}
