export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'APPLICATIONS': 'PROGRAMLAR',
            'SAMPLE'        : {
                'TITLE': 'Örnek',
                'BADGE': '15'
            },
            'TESTE'        : {
                'TITLE': 'Örnek'
            },
            'SCRUMBOARD'  : {
                'TITLE': 'Scrumboard'
            },
            'PROJECTS'    : {
                'TITLE': 'Proyectos',
                'TIMELINE': 'Timeline'
            },
            'ADMINISTRATION': 'ADMINISTRACIÓN', 
            'CLIENT'    : {
                'TITLE': 'Clientes'
            },  
            'PROJECTTYPE'    : {
                'TITLE': 'Tipos de Proyecto'
            },
            'CONFIGURACAO': 'Anbientacíon', 
            'USER'    : {
                'TITLE': 'Los usuarios',
                "SIGNUPS": "Inscritos",
                "PARTNERS": "Parceiros",
                "LOGIN": "Config. Login",
                "BANNER": "Config. Banner",
            }, 
            "APRENDIZADO": {
                "TITLE": "Mi aprendizaje",
                "CURSOS": "Cursos",
                "ROADMAP": "Ruta del conocimiento"
            },
        },
        "HELPDESK": "Central de Feedback",
        "MEMORY": "Experiencia",
        "GAMIFICATION": {
            "MEMORY": {
                "INFO": "Informaciones",
                "RANKING": "Ranking",
            }
        },
        "CONTENT": {
            "HOME": "Conteudo",
            "COURSE": {
                "CONTENTS": "Conteúdo",
                "BADGES": "Badges"
            }
        },
        "POSITIONS": {
            "POSITIONS": "Vagas",
            "OPEN": "Vagas Abertas",
            "FORM": "Cadastrar Vaga"
        },
        "JOBS": {
            "JOBS": "Vagas",
            "FIND": "Vagas Abertas",
            "MY": "Minhas Vagas"
        },
        "FINANCE": {
            "FINANCE": "Financeiro",
            "BUY": "Comprar Matches"
        }
    }
};
