import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, ChildActivationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  appRoot = location.origin + '/#';
  homeTarget = '/apps/home';

  home = '/apps/home'
  navigationHistory: Array<any> = [{
    url: this.home,
    icon: 'home'
  }];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.appRoot = location.origin + '/#';
  }

  ngOnInit() {
    this._router.events.subscribe(event => {
      if (location.href.includes("auth/login")) {
        return;
      }
      if (event instanceof NavigationEnd) {
        this.navigationHistory.push(event);
        if (this.navigationHistory.length > 3) {
          this.navigationHistory = [
            this.navigationHistory[0], 
            this.navigationHistory[this.navigationHistory.length - 2],
            this.navigationHistory[this.navigationHistory.length - 1],
          ];
        }
      }
    });
  }

  getSplitItem(item) {
    try {
      const splitUrl = item.url.split('/');
      return splitUrl[splitUrl.length - 1][0].toUpperCase() + splitUrl[splitUrl.length - 1].substr(1);
    } catch(e) {
      return "Item"
    }
  }
}
