import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id          : 'Classes',
        title       : 'Cursos',
        translate   : 'NAV.APRENDIZADO.TITLE',
        type        : 'collapsable',
        icon        : 'menu_books',
        isProfile   : false,
        children: [{
            id       : 'courses',
            title    : 'Cursos',
            translate: 'NAV.APRENDIZADO.CURSOS',
            type     : 'item',
            icon     : 'code',
            url      : 'apps/courses/student/dashboard',
            isProfile: false,
        }]
    }, {
        id          : 'Finance',
        title       : 'Financeiro',
        translate   : 'NAV.FINANCE.TITLE',
        type        : 'collapsable',
        icon        : 'attach_money',
        isProfile   : true,
        isSecretary : false,
        children: [{
            id       : 'payments',
            title    : 'Minhas mensalidades',
            translate: 'NAV.FINANCE.PAYMENTS',
            type     : 'item',
            icon     : 'payment',
            url      : 'apps/financial/payments/dashboard',
            isProfile: true,
        }]
    }, {
        id          : 'Secretary',
        title       : 'Secretaria',
        translate   : 'NAV.SECRETARY.TITLE',
        type        : 'collapsable',
        icon        : 'library_books',
        isProfile   : true,
        children: [{
            id       : 'open',
            title    : 'Abrir chamado',
            translate: 'NAV.SECRETARY.OPEN',
            type     : 'item',
            icon     : 'library_add',
            url      : 'apps/secretary/newCall/open',
            isProfile: true,
        }, {
            id       : 'opened',
            title    : 'Chamados abertos',
            translate: 'NAV.SECRETARY.REQUESTS',
            type     : 'item',
            icon     : 'history',
            url      : 'apps/secretary/called/myRequests',
            isProfile: true,
        }]
    }, {
        id          : 'Secretary',
        title       : 'Responder Chamados',
        translate   : 'NAV.SECRETARY.ANSWER',
        type        : 'item',
        icon        : 'library_books',
        url         : 'apps/secretary/answerCall/answer',
        isProfile   : false,
        isSecretary : true,
    },
    {
        id          : 'Library',
        title       : 'Biblioteca',
        translate   : 'NAV.LIBRARY.TITLE',
        type        : 'item',
        icon        : 'book',
        url         : 'apps/library/library',
        isProfile   : false,
    },
];