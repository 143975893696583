import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';

import {Observable, of, BehaviorSubject} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserModel } from '../../services/user.model';
import { datastore } from 'app/config';
import { TranslateService } from '@ngx-translate/core';
import _ = require('lodash');
import { PoNotification, PoNotificationService } from '@portinari/portinari-ui';

@Injectable({
  providedIn: 'root'
})
export class MailerService {
  
  user$: Observable<any>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private poNotification: PoNotificationService,
    private _httpClient: HttpClient,
  ){

    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if(user){
          return this.afs.doc<UserModel>(`${datastore}/users/${user.uid}`).valueChanges();
        }else{
          return of(null);
        }
      })
    )
  }

    senMail(){
        
        this._httpClient.post('https://us-central1-faculdadeiv2.cloudfunctions.net/enviarEmail', {
            assunto: "teste2",
            corpo: "true",
            corpoHtml: "<b>TOP COME</b>",
            destinatarios: "master10_@live.com"
        },{responseType: 'text'}).subscribe(data => {
            
        })
    }

    senMailCreateLogin(email, displayName, photo){
        let html = `<img width="600" src="https://firebasestorage.googleapis.com/v0/b/faculdadeiv2.appspot.com/o/images%2FLogo%20Faculdade%20iv2%20Final%20hori.png?alt=media&token=549fd4de-198f-499e-b588-86a5e8eedbec" /><br />
                        <p style="font: message-box;">Bem vindo <b style="color: teal;">${displayName}</b>, <br /><br /> ao sistema class da Faculdade IV2!</p>
                        <br />
                        <p style="font: message-box; color: color: teal;">Obrigado por se cadastrar na nossa plataforma. Lembre-se de acessar e se inscrever nos nossos cursos gratuítos!</p>
                        <br />
                        <br />
                        <p style="font: message-box;">Att. Administrador do sistema</p>`;

        let data = {
            destinatarios: email,
            corpoHtml: html,
            corpo: '',
            assunto: "Bem-vindo a Faculdade IV2!"
        }

        this._httpClient.post('https://us-central1-faculdadeiv2.cloudfunctions.net/enviarEmail',    
        data
        ,{responseType: 'text'}).subscribe(data => {
            
        })
    }

    senMailAdminLiberacao(email, displayName, photo){
        
        let date = new Date().toLocaleString();

        let html = `<img width="600" src="https://firebasestorage.googleapis.com/v0/b/faculdadeiv2.appspot.com/o/images%2FLogo%20Faculdade%20iv2%20Final%20hori.png?alt=media&token=549fd4de-198f-499e-b588-86a5e8eedbec" /><br />
        <p style="font: message-box; color: teal;">Olá Administrador,</p>
        <p style="font: message-box;">O usuário abaixo efetuou o cadastro e está aguardando a liberação dele no sistema, segue dados:</p>
        
        <p style="font: message-box;">Usuário: <b style="color: teal;">${displayName}</b></p>
        <p style="font: message-box;">E-mail: <b style="color: teal;">${email}</b></p>
        <p style="font: message-box;">Data da criação: <b style="color: teal;">${date}</b></p>
        <br />
        <p style="font: message-box; color: teal;">Acesse o sistema da Faculdade IV2 - DEV do Futuro <a href="${location.origin}">clicando aqui!</a> e analise a liberação!</p>
        <br />
        <p style="font: message-box;">Att. Administrador do sistema</p>`;

        this.afs.collection(`${datastore}/users`).ref.where('profile','==','Administrador').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
                let value = doc.data();

                let data = {
                    destinatarios: value.email,
                    corpoHtml: html,
                    corpo: '',
                    assunto: "Faculdade IV2 - Dev do Futuro - Liberação de usuário!"
                }
        
                this._httpClient.post('https://us-central1-faculdadeiv2.cloudfunctions.net/enviarEmail',
                data
                ,{responseType: 'text'}).subscribe(data => {
                    
                })
            })
        })


        
    }

    senMailLoginAccept(email, displayName, photo){
        let html = `<img width="600" src="https://firebasestorage.googleapis.com/v0/b/faculdadeiv2.appspot.com/o/images%2FLogo%20Faculdade%20iv2%20Final%20hori.png?alt=media&token=549fd4de-198f-499e-b588-86a5e8eedbec" /><br />
                        <p style="font: message-box;">Olá <b style="color: teal;">${displayName}</b>, <br /><br /> seu acesso ao sistema Faculdade IV2 - DEV do Futuro foi liberado!</p>
                        <br />
                        <p style="font: message-box; color: teal;">Efetue o login <a href="${location.origin}">Clicando aqui!</a></p>
                        <br />
                        <br />
                        <p style="font: message-box;">Att. Administrador do sistema</p>`;
    
        let data = {
            destinatarios: email,
            corpoHtml: html,
            corpo: '',
            assunto: "Faculdade IV2 - DEV do Futuro - Acesso liberado!"
        }
    
        this._httpClient.post('https://us-central1-faculdadeiv2.cloudfunctions.net/enviarEmail',   
         data
        ,{responseType: 'text'}).subscribe(data => {
            
        })
    }



}
